.select select {
  font-family: $family-sans-serif;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border: 2px solid transparent;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  border-color: #333;
}

.select.has-background-none select {
  background: none;
}
.select.is-borderless select {
  border-color: transparent;
}
.select.is-borderless select:hover {
  border-color: #b5b5b5;
}
.select.has-text-right select {
  // this is not fully cross browser - it doesn't work in Safari for example
  // switching the display direction rtl works, but semantically's not great
  text-align-last: right;
}
.select.is-radiusless select {
  // this is not fully cross browser - it doesn't work in Safari for example
  // switching the display direction rtl works, but semantically's not great
  border-radius: 0;
}
