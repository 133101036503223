$sliding-menu-border-color: #eee;
$sliding-menu-background: lighten($sliding-menu-border-color, 5);
$sliding-menu-color: #000;
$sliding-menu-border: 1px solid $sliding-menu-border-color;
$sliding-menu-background-hover: #ccc;
$sliding-menu-color-hover: #999;
$sliding-menu-align: left !default;
$sliding-menu-screen-opacity: 0.4 !default;
$sliding-menu-width: 280px;

.sliding-menu-content {
  position: fixed;
  top:    0;
  bottom: 0;
  @if $sliding-menu-align == left {
    right: auto;
    left: 0;
    transform: translateX(-$sliding-menu-width);
  } @else {
    right: 0;
    left: auto;
    transform: translateX($sliding-menu-width);
  }

  height: 100%;
  width: $sliding-menu-width;
  transition: all .25s linear;
  background: $sliding-menu-background;
  z-index: 999999;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  li a, li label, li button {
    border-bottom: $sliding-menu-border;
    color: $sliding-menu-color;
    display: block;
    font-weight: bold;
    padding: 1em;

    &:hover {
      background-color: $sliding-menu-background-hover;
      color: $sliding-menu-color-hover;
    }
  }

  &.is-visible {
    transform: translateX(0);
  }

  .toollink, a.toollink, button.toollink {
      color: black;
      font-family: EmbeddedTitleFont;
      text-transform: uppercase;
      font-size: 1em;
      text-decoration: none;
      white-space: nowrap;
  }
  button.toollink {
    width: $sliding-menu-width;
    text-align: left;
  }

  .indented {
    padding-left:50px;
  }
  a:hover,
  .toollink:hover,
  a.toollink:hover,
  button.toollink:hover {
      color: #668cb3;
  }

  .tag-label {
    font-size: 0.9em;
    vertical-align: text-top;
    padding: 0 0.5em;
    background-color: #ff5641;
    border-radius: 0.5em;
    color: white;
  }
}

.menu-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.15s ease-out 0s;
  background: $sliding-menu-border-color;
  opacity: 0;
  visibility: hidden;
  z-index: 999998;

  &.is-visible {
    opacity: $sliding-menu-screen-opacity;
    visibility: visible;
  }
}






.accordion {
  $base-font-color: black;
  $base-border-color: gainsboro !default;
  $base-line-height: 1.2em !default;
  $base-spacing: 1.2em !default;
  $dark-gray: #333 !default;
  $accordion-menu-border-color: $base-border-color;
  $accordion-menu-color: $base-font-color;
  $accordion-menu-border: 0px;
  $accordion-menu-background: lighten($accordion-menu-border-color, 10%);
  $accordion-menu-hover: lighten($accordion-menu-background, 2%);
  $accordion-menu-sub-background: darken($accordion-menu-background, 5%);
  $accordion-menu-sub-inset: darken($accordion-menu-sub-background, 6%);
  $accordion-menu-sub-hover: lighten($accordion-menu-sub-background, 2%);
  $accordion-menu-list-padding: ($base-spacing / 2) modular-scale(-1, $gutter, $golden);

  background-color: $accordion-menu-background;
  border: $accordion-menu-border;
  margin: 0 0 $base-spacing 0;
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;

    > a, > label, > button {
      color: $accordion-menu-color;
      display: block;
      padding: $accordion-menu-list-padding;
      text-decoration: none;
    }

    > button {
      background-color: $accordion-menu-background;
      margin-top: 0;
      margin-bottom: 0;
    }

    &:last-child {
      border: none;
    }

    &:focus,
    &:hover {
      background-color: $accordion-menu-hover;
    }
  }

  ul.submenu {
    display: none;

    li {
      background-color: $accordion-menu-sub-background;

      > button {
        background-color: $accordion-menu-sub-background;
      }

      &:first-child {
        border-top: $accordion-menu-border;
        box-shadow: inset 0 1px 1px $accordion-menu-sub-inset;
      }

      &:focus,
      &:hover {
        background-color: $accordion-menu-sub-hover;
      }
    }
  }

  .is-expanded {
    display: block;
    padding-bottom: 0;
  }
}
