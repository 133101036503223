@import 'variables';

/* Modal SCSS */
/* including postioning from http://lynn.io/2014/02/22/modalin/ */
.if-modal {
  $base-font-color: black;
  $base-border-radius: 3px !default;
  $base-background-color: white !default;
  $base-font-size: 1em !default;
  $base-line-height: 1.5em !default;
  $action-color: #477DCA !default;
  $dark-gray: #333 !default;
  $light-gray: #AAA !default;
  $medium-screen: 32.5em !default;
  $large-screen: 60em !default;
  $modal-padding: $base-margin;
  $modal-background: $base-background-color;
  $modal-close-color: $light-gray;
  $modal-image-height: 135px;
  $modal-image-width: $modal-image-height;
  $modal-trigger-image-width: 300px;

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  label img {
    border-radius: $modal-trigger-image-width / 2;
    display: block;
    max-width: $modal-trigger-image-width;
  }

  .modal-state {
    display: none;
  }

  .modal-fade-screen { // overlay
    transition: opacity 0.25s ease;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: rgba(0,0,0, 0.85);
    opacity: 0;
    padding-top: 0.6em;
    text-align: left;
    visibility: hidden;
    z-index: 99999999999;

    @media screen and (min-width: $large-screen) {
      padding-top: 8em;
    }

    .modal-bg {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      cursor: pointer;
    }

    &.modal-fade-screen-grey {
      background: rgba(238,238,238, 0.4);
    }
  }

  .modal-close {
    position: absolute;
    top: $modal-padding;
    right: $modal-padding;
    height: 1.5em;
    width: 1.5em;
    font-size: inherit;
    background: $modal-background;
    cursor: pointer;

    &:after,
    &:before {
      position: absolute;
      top: 3px;
      right: 3px;
      bottom: 0px;
      left: 50%;
      transform: rotate(45deg);
      height: 1.5em;
      width: 0.15em;
      background: $modal-close-color;
      content: '';
      display: block;
      margin: -3px 0 0 -1px;
    }

    &:hover:after,
    &:hover:before {
      background: darken($modal-close-color, 10%);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  .modal-inner {
    transition: opacity 0.25s ease;
    background: $modal-background;
    border-radius: $base-border-radius;
    margin-top: 0;
    margin: auto;
    overflow: auto;
    padding: $modal-padding;

    max-height: calc(100% - 100px);
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);

    width: 95%;

    @media screen and (min-width: $medium-screen) {
      padding: $modal-padding;
      width: 70%;
    }

    @media screen and (min-width: $large-screen) {
      width: 70%;
      max-width: 800px;
    }

    h1 {
      color: $base-font-color;
      margin-bottom: 0 0 0.6em 0;
      text-align: left;
      text-transform: capitalize;
    }

    p {
      color: $base-font-color;
      line-height: $base-line-height;
    }

    .modal-intro {
      font-weight: 800;
    }

    .modal-content {
      color: $base-font-color;

      @media screen and (min-width: $medium-screen) {
        columns: 2 8em;
      }
    }

    a.cta {
      color: white;
      display: inline-block;
      margin-right: 0.5em;
      margin-top: 1em;

      &:last-child {
        padding: 0 2em;
      }
    }
  }

  .modal-state:checked + .modal-fade-screen {
    opacity: 1;
    visibility: visible;
  }
}

.modal-open {
  overflow: hidden;
}
