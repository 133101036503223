
// Have a fixed footer
.content-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.content {
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: auto;
}

.footer {
	padding: 1em;
	flex-shrink: 0;
	background: #505050;
	ul li {
		color: white;
	}

	.container {
		max-width: 1144px;
		margin: auto;
	}

	.columns {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	// TODO: Replace with bulma when no we've got rid of base-old
	.column {
		display: block;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-ms-flex-negative: 1;
		flex-shrink: 1;
		padding: 0.75rem;
	}

	// Footer specific stuff
	.column {
		ul {
			margin-left: 0;
			list-style: none;
		}
	}

	.content {
		color: #FFF;
		text-align: center;
	}

	.copyright a {
		color: rgb(245,247,120); // Refactor to $yellow
		text-transform: uppercase;
	}

	a {
		color: rgb(186,166,255);
		text-transform: none;
	}

	span.no-wrap {
		white-space: nowrap;
	}
}