/* common element styles */
@import 'font/flipfont';

@mixin font-face($font-family, $local-name, $file-path, $weight: normal, $style: normal, $variant: normal) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-variant: $variant;
    src: url('../../fonts/#{$file-path}.woff2') format('woff2'),
      url('../../fonts/#{$file-path}.woff') format('woff'),
      url('../../fonts/#{$file-path}.ttf') format('truetype'),
      url('../../fonts/#{$file-path}.otf') format('opentype');
  }
}

@include font-face(EmbeddedTitleFont, 'Montserrat', 'Montserrat-SemiBold', 600);
@include font-face(BodyFont, 'Montserrat', 'Montserrat-Medium', 500);

@include font-face(NoteCondensed, 'Montserrat Condensed', 'montserrat-condensed');

body {
  font-size: 16px;
  font-family: BodyFont, 'Montserrat', sans-serif;
  font-weight: 500;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  font-family: EmbeddedTitleFont, 'Montserrat', sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 3em;
  letter-spacing: -0.04em;
}