div.logo {
  text-align: center;
  a {
    text-decoration: none;
    color: #000;
    letter-spacing:1px;
    padding-right:1px;
    margin-top: 0.4em;
    span {
      background: url("../../img/ideaflip-logo-animated.gif") center no-repeat;
      background-size: 258px 100px;
      padding: 40px 120px;
      position: relative;
    }
    &:hover {
      color: #666;
    }
  }
}