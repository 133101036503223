// Learn about this technique:
// @link https://moderncss.dev/pure-css-custom-styled-radio-buttons/

.control.is-suprematist {

  label.radio {
    // font-size: 2rem;
    // font-weight: bold;
    line-height: 1.1;
    display: inline-grid;
    grid-template-columns: 34px auto;
    gap: 0.5em;
    align-items: center;
  }

  label.radio + label.radio {
    margin-left: 1.5rem;
  }

  /*
  label.radio:focus-within {
    color: black;
  }
  */
  
  /* circle */
  input[type="radio"] {
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;

    font: inherit;
    color: currentColor;
    width: 34px;
    height: 34px;
    border: 1px solid #dbdbdb;
    border-radius: 50%;

    display: grid;
    place-content: center;
  }

  label.radio:hover input[type="radio"] {
    border-color: #b5b5b5;
  }

  /* dot */
  input[type="radio"]::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #363636;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  // use #{} quoting to use browser min/max and avoid 'Incompatible units' error
  input[type="radio"]:focus {
    border-color: #baa6ff;
    // outline: #{'max(2px, 0.15em)'} solid currentColor;
    // outline-offset: #{'max(2px, 0.15em)'};
  }
}
