
// Add our font families
$family-sans-serif: BodyFont, sans-serif;
$family-sans-serif-thin: $family-sans-serif;
$family-sans-serif-regular: EmbeddedTitleFont, sans-serif;
$button-family: EmbeddedTitleFont, sans-serif;

// don't overbold everything!
$content-heading-weight: normal;

// 3. Set the derived variables

//$orange:       hsl(14,  100%, 53%) !default
$pink:         rgb(254,139,230);
$yellow:       rgb(245,247,120);
$green:        rgb(150,250,152);
$turquoise:    rgb(162,250,236);
$blue:         rgb(114,236,255);
$purple:       rgb(147,115,255);
$red:          #ff7373;
//rgba(237,239,189,1)">Cream</span>

$danger-dark:  rgb(175, 24, 30);
$danger-light: rgb(255,240,244);

$primary: $yellow;
//$info: $purple;

$link: $purple;
//$link-visited: $purple;
$link-focus-border: $purple;

$alert-icon-size: 32px;
$alert-success-color: rgb(47, 151, 47);
$alert-error-color: rgb(179, 0, 0);

$radius-small: 0;
$radius: 0;
$radius-large: 0;
// $control-radius: 0;

// adjust breakpoints to be a bit more midpointy:
//   <= 559px is mobile
//   560px - 879px is tablet portrait
//   880px - 1191px is desktop (& tablet landscape)
//   >= 1192px is widescreen
// see: https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
$tablet: 560px;  // half col 320px
$desktop: 880px;   // third col 312px
// don't get too wide
$widescreen: 1192px;
$fullhd-enabled: false;

$base-margin: 20px;
$base-margin-sm: 16px;

$large-rounded-input-height: 46px;

$board-background-color: #D9E8EE;

// square tabs
$tabs-link-active-color: #333;
$tabs-boxed-link-radius: 0;
$tabs-boxed-link-active-background-color: #f5fcff;
$tabs-boxed-link-hover-background-color: rgb(235, 242, 245);
