// header styles, not tide to either bourbon or bulma
// (so can be used in base-old & style.scss)

@mixin header-nav-text {
  display: block;
  font-size: 0.9em;
  font-family: EmbeddedTitleFont;
  letter-spacing: -0.02em;
  color: #777;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    color: #444;
  }
}

@mixin header-menu-button {
  float: right;
  border: none;
  background-color: transparent;
  font-size: 0.5em;
  margin-top: -1em;
  padding: 0 0 0 32px;
  .ff-menu {
    &::before {
      vertical-align: bottom;
    }
    font-size: 4em;
    display: block;
  }
}

@mixin header-menu-button-mobile {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  line-height: 1.5;
  font-size: 0.9em;
  margin-top: 0;
  .ff-menu {
    margin-left: 0.2em;
    font-size: 1.2em;
    display: inherit;
  }
}
