// https://medium.com/claritydesignsystem/pure-css-accessible-checkboxes-and-radios-buttons-54063e759bb3

.checkbox.is-suprematist {
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  label {
    position: relative;
    display: inline-block;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 44px;
  }

  label::before,
  label::after {
    position: absolute;
    content: "";

    /*Needed for the line-height to take effect*/
    display: inline-block;
  }

  /*Outer box of the fake checkbox*/
  label::before {
    height: 34px;
    width: 34px;

    background-color: white;
    border: 1px solid #dbdbdb;
    left: 0;

    /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
     *to vertically center it.
     */
    top: 3px;
  }

  &:hover label::before {
    border-color: #b5b5b5;
  }

  /*Checkmark of the fake checkbox*/
  label::after {
    height: 8px;
    width: 16px;
    border-left: 3px solid;
    border-bottom: 3px solid;
    border-color: #363636;

    transform: rotate(-45deg);
    outline: 1px solid transparent; // hack to prevent antialias atrifacts in firefox

    left: 9px;
    top: 14px;
  }

  /*Hide the checkmark by default*/
  input[type="checkbox"] + label::after {
    content: none;
  }

  /*Unhide on the checked state*/
  input[type="checkbox"]:checked + label::after {
    content: "";
  }

  /*Adding focus styles on the outer-box of the fake checkbox*/
  input[type="checkbox"]:focus + label::before {
    border-color: #9373ff;
    box-shadow: 0 0 0 0.125em rgba(147, 115, 255, 0.25);
  }
}
