// latest 'global' stylesheet - uses bulma.io, see our /style/guide/

@import 'font/fonts';

// Set our own initial variables before importing the rest of Bulma
@import '_variables';
@import 'bulma';

@import 'modal-dialog';

// 5. Specific Ideaflip overrides

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $family-sans-serif-regular;
  letter-spacing: -0.04em;
}

body {
  letter-spacing: -0.02em;
}

strong {
  font-family: $family-sans-serif-regular;
}

// have square bullets
.content ul {
  list-style-type: square;
}

// have flat input fields
.input,
.textarea {
  box-shadow: none;
}

// for 'placeholder' option on dropdowns
select:invalid {
  color: #a9a9a9;
}

// 6. Optional Ideaflip decorators

// use is-suprematist class to have strong black/white button
.button.is-suprematist {
  color: black;
  border: 2px solid black;
  font-family: $family-sans-serif-regular;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  padding: 0.13em 1em;
  font-size: 1.2em;
  height: 1.875em;
  &:hover, &:focus {
    color: white;
    background-color: black;
    border-color: black;
  }
  &:active {
    color: black;
    background-color: white;
    border-color: black;
  }

  &.is-suprematist-light {
    border: 1px solid #333;
    font-family: $family-sans-serif-thin;
    color: #333;
    &:hover, &:focus {
      color: white;
    }
  }

}

.button.is-text {
  color: black;
  border: none;
  background: none;
  font-family: $family-sans-serif-regular;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  font-size: 1.2em;
}

// use is-lozenge class to have lozenge shaped buttons
.button.is-lozenge {
  border-radius: 290486px;
  font-family: $family-sans-serif-regular;
  letter-spacing: -0.02em;
  color: black;
  border: none;
  font-size: 0.8em;
  height: 2.8125em;
  padding-left: 2em;
  padding-right: 2em;
//  box-shadow: 0 1em 1em -1.1em black;
  &:hover {
    color: grey;
  }
  &:active {
    color: black;
//    box-shadow: 0 0.9em 1em -1.1em black;
  }
}

// for use with labelling a set of radio buttons
//.fieldset {
//  border: none;
//}
//.legend {
//}

// 7. Standard Page decorations

@import 'logo';

// we're trying to eliminate bourbon / neat
$em-base: 16px;
$output-bourbon-deprecation-warnings: true;
@import '../../node_modules/bourbon/app/assets/stylesheets/functions/_modular-scale.scss';
$gutter: modular-scale(2, 1em, $golden);
$sliding-menu-align: right;
$sliding-menu-screen-opacity: 0.8;
@import 'sliding-menu';

div.heading h1 {
  font-size: 3rem;
  margin-right: 0.12em;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: none;
  color: black;
}

.bold-page {
  background-color: #fafafa;
  &:not(:last-child) {
    margin-bottom: 0;
  }

  .header-section {
    padding-top: 0;
    padding-bottom: 200px;
    background-color: rgba(245,247,120,1);

    div.sign_in {
      a.sign-in, a.sign-out, button.sliding-menu-button {
        color: #333;
        &:hover {
          color: #000;
        }
      }
    }

    .heading {
      margin-bottom: 30px;
    }
  }

  .bold-page-container {
    margin-top: -200px;
    margin-bottom: 3rem;
  }

}

.bold-page-content {
  padding: 1.5rem;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
}

@import 'header';

div.header-content {
  a.sign-in, a.sign-out, button.sliding-menu-button, a.header-breadcrumb {
    @include header-nav-text;
  }
  a.sign-in, a.sign-out {
    float: right;
    padding-left: 32px;
  }
  button.sliding-menu-button {
    @include header-menu-button;
    @include until($tablet) {
      @include header-menu-button-mobile;
    }
  }
  h1.title {
    margin-top: 0;
  }
  a.header-breadcrumb + h1.title {
    margin-top: 16px;
  }
  h2.subtitle {
    color: #888;
  }
}

.full-height {
  height: 100%;
}


@import 'footer';

@import 'checkbox';
@import 'radio';
@import 'select';

.sticky {
  height: 160px;
  width: 160px;
  background-color: #f5f778;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  font-size: 22px;
  font-weight: 1000;
  color: black;
  padding: 20px;
  position: relative;
  margin: 16px;

  transform: rotate(-4deg);

  box-shadow: 0px 0px 3px rgba(0,0,0,0.12), 1px 1px 3px rgba(0,0,0,0.24), 2px 2px 4px rgba(0,0,0,0.12);

  &.green {
    background-color: #96fb98;
  }

  &.blue {
    background-color: #74ecff;
  }

  &.pink {
    background-color: #fe8be7;
  }

  &.small {
    height: 120px;
    width: 120px;
  }
}


.sticky-tag {
  text-transform: uppercase;
  background-color: black;
  color: white;
  font-size: 14px;
  white-space: nowrap;
  padding: 0 10px;
  box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.36);
}

.sticker {
  width: 64px;
  height: 64px;
  background-color: #76c761;
  border-radius: 32px;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.36);
  border: 6px solid white;

  &.light-bulb {
    background-color: #4bc2e7;
  }
}

.sticky, .sticky-tag, .sticker {
  &.left-1 {
    transform: rotate(-9deg);
  }
  &.left-2 {
    transform: rotate(-12deg);
  }
  &.right-1 {
    transform: rotate(7deg);
  }
  &.right-2 {
    transform: rotate(14deg);
  }

  &.top-right {
    position: absolute;
    top: 0%;
    left: 100%;
  }

  &.bottom-right {
    position: absolute;
    top: 100%;
    left: 100%;
  }

  &.bottom-left {
    position: absolute;
    top: 100%;
    left: 0%;
  }
}

.yellow {
  background-color: rgba(245,247,120,1);
}
.light-blue {
  background-color: #EFF3F8;
  &.content {
    margin-bottom: 0;
    padding-bottom: 1.5rem;
  }
}