// created from template/_flipfont.scss

@font-face {
  font-family: "flipfont";
  src: url('../flipfont.woff2') format('woff2'),
    url('../flipfont.woff') format('woff'),
    url('../flipfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.ff:before {
  display: inline-block;
  font-family: "flipfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ff-lg {
  font-size: 1.6em;
  line-height: 0.75em;
  vertical-align: -30%;
}

.ff-mu {
  font-size: 1.6em;
  line-height: 0.75em;
  vertical-align: -30%;
  padding-right:10px;
}
.ff-mu:before {
  // may not be needed once sanitize is used everywhere
  vertical-align: baseline;
}

.ff-2x { font-size: 2.5em; }
.ff-3x { font-size: 3em; }
.ff-4x { font-size: 4em; }
.ff-5x { font-size: 5em; }
.ff-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.ff-ideaflip-logo:before { content: "\E001" }
.ff-back:before { content: "\E004" }
.ff-front:before { content: "\E005" }
.ff-trash:before { content: "\E006" }
.ff-edit:before { content: "\E007" }
.ff-lasso:before { content: "\E008" }
.ff-rectangle:before { content: "\E009" }
.ff-locked:before { content: "\E00A" }
.ff-unlocked:before { content: "\E00B" }
.ff-scroll-down:before { content: "\E00C" }
.ff-scroll-up:before { content: "\E00D" }
.ff-settings:before { content: "\E00E" }
.ff-ok:before { content: "\E00F" }
.ff-cancel:before { content: "\E010" }
.ff-download:before { content: "\E011" }
.ff-inbox:before { content: "\E012" }
.ff-megaphone:before { content: "\E013" }
.ff-lightbulb:before { content: "\E014" }
.ff-rocket:before { content: "\E015" }
.ff-delete:before { content: "\E017" }
.ff-case:before { content: "\E018" }
.ff-home:before { content: "\E019" }
.ff-undo:before { content: "\E01A" }
.ff-eye:before { content: "\E01B" }
.ff-lnr-mic:before { content: "\E01C" }
.ff-lnr-mic-mute:before { content: "\E01D" }
.ff-bar-chart:before { content: "\E01E" }
.ff-credit-card:before { content: "\E01F" }
.ff-help:before { content: "\E020" }
.ff-invite:before { content: "\E021" }
.ff-heart:before { content: "\E022" }
.ff-cloud-download:before { content: "\E023" }
.ff-phone:before { content: "\E024" }
.ff-hangup:before { content: "\E025" }
.ff-permissions:before { content: "\E026" }
.ff-layers:before { content: "\E027" }
.ff-double-up:before { content: "\E028" }
.ff-double-down:before { content: "\E029" }
.ff-copy:before { content: "\E02A" }
.ff-copy-one:before { content: "\E02B" }
.ff-trash-one:before { content: "\E02C" }
.ff-plus-square:before { content: "\E031" }
.ff-plus-circle:before { content: "\E032" }
.ff-plus:before { content: "\E033" }
.ff-pencil:before { content: "\E034" }
.ff-clock:before { content: "\E035" }
.ff-sort:before { content: "\E036" }
.ff-search:before { content: "\E037" }
.ff-minimise:before { content: "\E038" }
.ff-menu:before { content: "\E039" }
.ff-licences:before { content: "\E03A" }
.ff-signout:before { content: "\E03B" }
.ff-building:before { content: "\E03C" }
.ff-notebook:before { content: "\E03D" }
.ff-info-circle:before { content: "\E03E" }
.ff-wrench:before { content: "\E03F" }
.ff-align-left:before { content: "\E040" }
.ff-align-center:before { content: "\E041" }
.ff-align-right:before { content: "\E042" }
.ff-angle-left:before { content: "\E043" }
.ff-angle-right:before { content: "\E044" }
.ff-minus:before { content: "\E045" }
.ff-map:before { content: "\E046" }
.ff-mouse-click:before { content: "\E047" }
.ff-minimise2:before { content: "\E048" }
.ff-mouse-scroll:before { content: "\E049" }
.ff-film:before { content: "\E04A" }
.ff-film-fill:before { content: "\E04B" }
.ff-pointer:before { content: "\E04C" }
.ff-pointer-fill:before { content: "\E04D" }
.ff-diagonal-arrow:before { content: "\E04E" }
.ff-clockwise:before { content: "\E04F" }
.ff-widdershins:before { content: "\E050" }
.ff-smaller:before { content: "\E051" }
.ff-bigger:before { content: "\E052" }
.ff-resize-rotate:before { content: "\E053" }
.ff-external-link:before { content: "\E054" }
.ff-shape-square:before { content: "\E055" }
.ff-shape-circle:before { content: "\E056" }
.ff-shape-hexagon:before { content: "\E057" }
.ff-shape-diamond:before { content: "\E058" }
.ff-shape-rectangle:before { content: "\E059" }
.ff-shape-card:before { content: "\E05A" }
.ff-upload:before { content: "\E05C" }


.ff-outline {
  position: relative;
}

.ff.ff-outline:before {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  z-index: 1;
}

.ff-outline:after {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-text-stroke: 5px rgba(217, 232, 238, 0.9);
  z-index: 0;
  font-family: "flipfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ff-outline.ff-ideaflip-logo:after { content: "\E001" }
.ff-outline.ff-back:after { content: "\E004" }
.ff-outline.ff-front:after { content: "\E005" }
.ff-outline.ff-trash:after { content: "\E006" }
.ff-outline.ff-edit:after { content: "\E007" }
.ff-outline.ff-lasso:after { content: "\E008" }
.ff-outline.ff-rectangle:after { content: "\E009" }
.ff-outline.ff-locked:after { content: "\E00A" }
.ff-outline.ff-unlocked:after { content: "\E00B" }
.ff-outline.ff-scroll-down:after { content: "\E00C" }
.ff-outline.ff-scroll-up:after { content: "\E00D" }
.ff-outline.ff-settings:after { content: "\E00E" }
.ff-outline.ff-ok:after { content: "\E00F" }
.ff-outline.ff-cancel:after { content: "\E010" }
.ff-outline.ff-download:after { content: "\E011" }
.ff-outline.ff-inbox:after { content: "\E012" }
.ff-outline.ff-megaphone:after { content: "\E013" }
.ff-outline.ff-lightbulb:after { content: "\E014" }
.ff-outline.ff-rocket:after { content: "\E015" }
.ff-outline.ff-delete:after { content: "\E017" }
.ff-outline.ff-case:after { content: "\E018" }
.ff-outline.ff-home:after { content: "\E019" }
.ff-outline.ff-undo:after { content: "\E01A" }
.ff-outline.ff-eye:after { content: "\E01B" }
.ff-outline.ff-lnr-mic:after { content: "\E01C" }
.ff-outline.ff-lnr-mic-mute:after { content: "\E01D" }
.ff-outline.ff-bar-chart:after { content: "\E01E" }
.ff-outline.ff-credit-card:after { content: "\E01F" }
.ff-outline.ff-help:after { content: "\E020" }
.ff-outline.ff-invite:after { content: "\E021" }
.ff-outline.ff-heart:after { content: "\E022" }
.ff-outline.ff-cloud-download:after { content: "\E023" }
.ff-outline.ff-phone:after { content: "\E024" }
.ff-outline.ff-hangup:after { content: "\E025" }
.ff-outline.ff-permissions:after { content: "\E026" }
.ff-outline.ff-layers:after { content: "\E027" }
.ff-outline.ff-double-up:after { content: "\E028" }
.ff-outline.ff-double-down:after { content: "\E029" }
.ff-outline.ff-copy:after { content: "\E02A" }
.ff-outline.ff-copy-one:after { content: "\E02B" }
.ff-outline.ff-trash-one:after { content: "\E02C" }
.ff-outline.ff-plus-square:after { content: "\E031" }
.ff-outline.ff-plus-circle:after { content: "\E032" }
.ff-outline.ff-plus:after { content: "\E033" }
.ff-outline.ff-pencil:after { content: "\E034" }
.ff-outline.ff-clock:after { content: "\E035" }
.ff-outline.ff-sort:after { content: "\E036" }
.ff-outline.ff-search:after { content: "\E037" }
.ff-outline.ff-minimise:after { content: "\E038" }
.ff-outline.ff-menu:after { content: "\E039" }
.ff-outline.ff-licences:after { content: "\E03A" }
.ff-outline.ff-signout:after { content: "\E03B" }
.ff-outline.ff-building:after { content: "\E03C" }
.ff-outline.ff-notebook:after { content: "\E03D" }
.ff-outline.ff-info-circle:after { content: "\E03E" }
.ff-outline.ff-wrench:after { content: "\E03F" }
.ff-outline.ff-align-left:after { content: "\E040" }
.ff-outline.ff-align-center:after { content: "\E041" }
.ff-outline.ff-align-right:after { content: "\E042" }
.ff-outline.ff-angle-left:after { content: "\E043" }
.ff-outline.ff-angle-right:after { content: "\E044" }
.ff-outline.ff-minus:after { content: "\E045" }
.ff-outline.ff-map:after { content: "\E046" }
.ff-outline.ff-mouse-click:after { content: "\E047" }
.ff-outline.ff-minimise2:after { content: "\E048" }
.ff-outline.ff-mouse-scroll:after { content: "\E049" }
.ff-outline.ff-film:after { content: "\E04A" }
.ff-outline.ff-film-fill:after { content: "\E04B" }
.ff-outline.ff-pointer:after { content: "\E04C" }
.ff-outline.ff-pointer-fill:after { content: "\E04D" }
.ff-outline.ff-diagonal-arrow:after { content: "\E04E" }
.ff-outline.ff-clockwise:after { content: "\E04F" }
.ff-outline.ff-widdershins:after { content: "\E050" }
.ff-outline.ff-smaller:after { content: "\E051" }
.ff-outline.ff-bigger:after { content: "\E052" }
.ff-outline.ff-resize-rotate:after { content: "\E053" }
.ff-outline.ff-external-link:after { content: "\E054" }
.ff-outline.ff-shape-square:after { content: "\E055" }
.ff-outline.ff-shape-circle:after { content: "\E056" }
.ff-outline.ff-shape-hexagon:after { content: "\E057" }
.ff-outline.ff-shape-diamond:after { content: "\E058" }
.ff-outline.ff-shape-rectangle:after { content: "\E059" }
.ff-outline.ff-shape-card:after { content: "\E05A" }
.ff-outline.ff-upload:after { content: "\E05C" }
